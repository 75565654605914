@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&display=swap');

$navblue: #3D8EF9;
$navpurple: #340160;
$cyan: #00C4CC;
$purple: rgb(180, 12, 180);
$letterwhite: #E8E2FF;


@mixin fadein-animation {

  opacity: 0;
  animation-name: fadein;
  animation-duration: 1200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: relative;
}


@keyframes fadein {
  0% {
    opacity: 0;
    top: 60px;
  }

  100% {
    opacity: 1;
    top: 0px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Merriweather Sans', sans-serif;



}

body {
  background-image: linear-gradient(70deg, #7463E0 8%, #0F3C9A 85%);
  min-height: 800px;

}

.navbar {
  display: flex;
  //

  height: 90px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: $letterwhite;

  font-weight: 400;
  font-size: 1.5rem;
  flex-direction: row;
  // position: relative;
  position: sticky;
  top: 0;
  background: linear-gradient(to bottom, #5959CD, #1349bd);
  z-index: 60;

  .backtotop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    background-color: grey;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 100%;
    padding-top: 3px;
    z-index: 60;

    &:hover {
      background-color: white;
      color: grey;
    }

  }


  .navbar-first {
    margin-right: auto;
    min-width: 210px;
    margin-left: 3%;
    cursor: pointer;

  }

  .hamburgermenu {
    display: block;

    @media (min-width:900px) {
      display: none;
    }

  }

  @keyframes fadeinnav {
    0% {
      opacity: 0;
      top: 50px;
    }

    100% {
      opacity: 1;
      top: 0px;
    }
  }

  a {
    margin: 0 20px;
    text-decoration: none;
    color: white;
    display: none;
    position: relative;

    @media (min-width:900px) {
      display: block;
      animation-name: fadeinnav;
      animation-duration: 1200ms;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

  }

  .navbarside {

    position: fixed;
    top: 0;
    height: 100%;
    width: 300px;
    right: -300px;
    transition: all 0.5s ease-in-out;
    background-color: rgb(223, 254, 255);
    z-index: 20;

    .blackbar {
      background-color: rgb(34, 203, 209);
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        color: #2244A7;
        cursor: pointer;
        background-color: rgb(34, 203, 209)
      }


    }

    .special {
      margin-top: 250px;
      color: rgb(197, 238, 240);
    }

    p {
      background-color: rgb(223, 254, 255);
      padding-left: 20px;
      color: #2244A7;
      font-size: 1rem;
      margin: 30px 0;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        opacity: 0.4;
      }



    }
  }

  .slide-in {
    right: 0;

  }

}

.frontpage-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 40px;
  min-height: 800px;
  @include fadein-animation;

  .frontpage-container-left {
    max-width: 600px;

    p {
      color: $cyan;
      color: $letterwhite;
      margin: 0;
      margin-top: 30px;
      font-size: 1.2rem;
      margin-bottom: 25px;


    }

    h2 {
      color: cyan;
      font-size: 4rem;
    }

    h3 {
      font-size: 3rem;
      color: rgb(190, 185, 185);
      margin-bottom: 20px;
    }

    h4 {
      font-size: 1.8rem;
      color: $letterwhite;
      margin-bottom: 0px;

    }

    h4:nth-child(5) {
      margin-bottom: 40px;
    }

    button {
      width: 200px;
      height: 44px;
      background-color: transparent;
      color: $cyan;
      font-weight: 600;
      font-size: 1.1rem;
      border-radius: 4px;
      border: $cyan 1.2px solid;
      margin-bottom: 40px;


      &:hover {
        background-color: $cyan;
        color: #0F3C9A;
      }
    }
  }

  .frontpage-container-right {
    max-width: 400px;

    img {
      max-width: 300px;
      width: 300px;
      height: 300px;

      border-radius: 100%;
      object-fit: cover;
      object-position: top;

      @media (min-width:800px) {
        margin-top: 60px;
        margin-left: 40px;
      }

    }
  }
}

.projects {
  @include fadein-animation;
  color: white;

  .projects-header {
    display: grid;
    grid-template-columns: 1fr 400px 1fr;
    margin-top: 30px;

    @media (min-width:900px) {
      grid-template-columns: minmax(75px, 1fr) 780px 2fr;
    }

    h2 {
      font-size: 2rem;
      margin-bottom: 4px;
    }

  }

}


.projectscard {
  margin: 40px 80px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width:900px) {
    flex-wrap: nowrap;
  }


  .projectscard-left-container {
    min-width: 360px;
    max-width: 400px;
    color: $letterwhite;

    @media (min-width:900px) {
      margin-right: 30px;
    }

    // margin-right: 20px;

    // margin-right: auto;
    span {
      color: yellow;
      font-weight: 600;
    }


    h2 {
      margin-bottom: 10px;
    }

    p,
    strong {
      margin-bottom: 20px;
    }




  }

  .project-title {
    color: $cyan;
  }

  .projectscard-left-icons {
    display: flex;
    margin-bottom: 20px;

  }

  .viewproject {
    width: 160px;
    height: 35px;
    background-color: transparent;
    border: $cyan 2px solid;
    border-radius: 4px;
    color: $cyan;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    .button-link {
      margin-left: 4px;
      margin-top: 2px;
    }

    &:hover {
      background-color: $cyan;
      color: purple;
    }

  }

  .projectscard-right-container {
    width: 50%;
    min-width: 360px;
    max-width: 500px;



  }

  .projectscard-right-canvas-first {
    width: 100%;
    height: 380px;
    display: inline-block;
    position: relative;

    img {
      width: 50%;
      border-radius: 2px;
      position: absolute;
      //x y blue color
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    }

    .canvas-first {
      margin-top: 10%;
      margin-left: 1%;
      rotate: (-18deg);
      filter: drop-shadow(-5rem -1rem 5rem rgba(255, 21, 247, 0.35));

    }

    .canvas-second {
      margin-top: 20%;
      margin-left: 48%;
      rotate: (13deg);
      filter: drop-shadow(2rem -1rem 2rem rgba(255, 21, 247, 0.35));
    }

    .canvas-third {
      margin-top: 40%;
      margin-left: 23%;
      rotate: (0deg);
      filter: drop-shadow(0 1rem 0.6rem rgba(255, 21, 247, 0.35));
    }


    .canvas-four-first {
      margin-top: 13%;
      margin-left: 5%;
      rotate: (-18deg);
      filter: drop-shadow(-1rem -1rem 1.5rem rgba(255, 21, 247, 0.4));

    }

    .canvas-four-second {
      margin-top: 12%;
      margin-left: 44%;
      rotate: (13deg);
      filter: drop-shadow(2rem -1.6rem 1.7rem rgba(255, 21, 247, 0.45));
    }

    .canvas-four-third {
      margin-top: 45%;
      margin-left: 6%;
      rotate: (-18deg);
      filter: drop-shadow(-2rem 2rem 1.6rem rgba(255, 21, 247, 0.35));
    }

    .canvas-four-fourth {
      margin-top: 43%;
      margin-left: 46%;
      rotate: (13deg);
      filter: drop-shadow(3rem 1.6rem 1.8rem rgba(255, 21, 247, 0.35));
    }
  }

  .projectscard-right-canvas-second {
    width: 100%;
    height: 380px;
    display: inline-block;
    position: relative;

    img {
      width: 50%;
      border-radius: 2px;
      position: absolute;


    }


  }
}

.icon {
  width: 40px;
}

.icon-express-container {
  width: 40px;
}

.icon-aws {
  margin-top: 5px;
}

.icon-express {
  width: 36px;
  height: 36px;
  object-fit: cover;
  margin-top: 2px;
  border-radius: 100%;
}

.products {
  margin: 20px 60px;

  h1 {
    width: 100%;
    margin-left: 10px;
  }

  .products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 45%;
      margin: 10px 10px;
      border-radius: 6px;
      min-width: 360px;
    }
  }
}

.roadmap {

  @include fadein-animation;
  margin: 50px 10%;
  min-height: 900px;


  h1 {
    text-align: center;
    color: $letterwhite;
    margin-bottom: 10px;

  }

  .roadmap-icon-bar {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }

  .roadmap-container {

    color: $letterwhite;
    min-width: 360px;
    max-width: 400px;
    margin: 40px auto;

    .bold {
      font-weight: 600;
      color: yellow;
    }

    * {
      margin-bottom: 20px;
    }
  }

  .contact-me-button {
    color: white;
    background-color: #3D8EF9;
    text-align: center;
    max-width: 500px;
    margin: 20px auto;
    display: grid;
    place-items: center;
    border-radius: 4px;
    padding: 20px 0px;

    &:hover {
      background-color: white;
      color: #3D8EF9;
      border: #3D8EF9 solid 3px;
    }
  }

}

// .my-component {
//   opacity: 20%;
//   transition: opacity 2s ease-in-out;
// }

// .my-component.loaded {
//   opacity: 70%;
// }
.about {
  margin-left: 20px;
  margin-right: 20px;

  min-height: 1700px;


  .about-container {
    // opacity: 20%;
    // transition: opacity 2s ease-in-out;
    min-width: 320px;
    max-width: 600px;
    margin: 40px auto;
    color: $letterwhite;

    @include fadein-animation;

    img {
      width: 100%;
      height: 260px;
      object-fit: cover;
      border-radius: 6px;
      margin: 30px 0;
    }
  }

  span {
    font-weight: 600;
    color: yellow;
  }

  p,
  h2 {
    margin-bottom: 20px;
  }
}

.contact {
  @include fadein-animation;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  max-width: 700px;
  margin: 40px auto;
  margin-bottom: 100px;


  // flex-wrap: wrap;
  * {
    color: white;
  }

  .about-img {
    max-width: 200px;
    width: 300px;
    height: 200px;

    border-radius: 100%;
    object-fit: cover;
    object-position: top;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 30px;

  }

  form {
    min-width: 200px;

    * {
      color: white;
      width: 100%;

    }

    span {
      color: rgb(0, 255, 234);
      text-decoration: underline;
      cursor: pointer;
    }
  }


  input::placeholder,
  textarea::placeholder {
    color: rgb(18, 44, 161);
    font-size: 0.8rem;

  }

  input,
  textarea {
    padding: 5px 5px;
    background-color: rgb(191, 203, 243);
    color: blue;
    border-radius: 2px;


  }

  form {
    p {
      font-size: 0.8rem;
      margin: 5px 0px;
    }

    position: relative;
  }

  .over-form {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;

    p {
      width: 60%;
    }
  }


  textarea {
    height: 160px;
  }

  button {
    background-color: #3D8EF9;
    border: white solid 2px;
    border-radius: 4px;
    height: 40px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 1rem;

    &:hover {
      color: #3D8EF9;
      background-color: mix(#3D8EF9, white, 20%);
      border: #3D8EF9 solid 2px;

    }
  }
}

.footer {
  text-align: center;
  color: $letterwhite;
}

.mp-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 30px;

}

.mp-header {
  color: white;
  max-width: 800px;
  margin: 0px 20px;
}


.marketplace {

  @media (min-width:900px) {
    display: grid;
    grid-template-columns: 1fr 930px 1fr;

  }






  .marketplace-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 40px;


    .lightbox {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      display: grid;
      place-items: center;
      color: black;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 200;

      .lightbox-middle-container {

        width: 300px;
        height: 600px;


        @media (min-width:830px) {
          width: 800px;
          height: 400px;
        }

        display: flex;
        flex-wrap: wrap;
      }

      .lightbox-left {
        width: 300px;
        height: 300px;
        position: relative;

        .lightbox-left-abs {
          position: absolute;
          width: 50px;
          height: 50px;
          display: flex;
          top: 250px;
          left: 0px;

          * {
            margin-bottom: 10px;
          }

          @media (min-width:830px) {
            top: 400px;
            left: 0px;


            * {
              margin-right: 5px;
            }
          }

          .lightbox-abs-img {
            width: 50px;
            height: 50px;
            border: rgb(182, 182, 182) solid 3px;

            @media (min-width:830px) {
              width: 80px;
              height: 80px;
            border: grey solid 5px;

            }
            // object-fit: cover;
            // object-position: center;
          }
        }

        @media (min-width:830px) {
          width: 400px;
          height: 400px;
        }
      }

      .lightbox-img {

        width: 300px;
        height: 300px;


        @media (min-width:830px) {
          width: 400px;
          height: 400px;
        }

        object-fit: cover;
        object-position: center;
      }

      .lightbox-right {
        width: 300px;
        height: 300px;

        @media (min-width:830px) {
          width: 400px;
          height: 400px;
        }

        background-color: #212646;
        color: white;
        display: grid;
        place-items: center;


        .product-description {
          font-size: 1rem;
        }

        p {
          font-size: 0.7rem;
        }

        h2,
        p {
          margin-bottom: 10px;
        }


        .lightbox-right-inner {
          margin-left: 20px;
        }
      }
    }



    .img-container {
      position: relative;
      margin-top: 30px;
      width: 200px;
      height: 200px;
      margin-right: 30px;
      border-radius: 2px;
      box-shadow: (3px 3px 5px 1px rgba(0,0,0,0.4));

      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        object-position: center;
      }

      .img-container-abs {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        $shadecolor: rgba(0, 0, 0, 0.6);
        $start: -10%;
        $end: 40%;

        background: linear-gradient(0deg, $shadecolor $start, rgba(0, 0, 0, 0) $end),
          linear-gradient(180deg, $shadecolor $start, rgba(0, 0, 0, 0) $end);

        color: white;
        padding: 10px;

        h4 {
          padding-top: 130px;
          padding-left: 110px;
        }
      }
    }
  }
}